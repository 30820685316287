import React from 'react';

export default class Skills extends React.Component {
    render() {
        return (
            <div id="skills">
                <div id="mostE">
                    <p><b>Most Experience</b></p>
                    <p>C#</p>
                    <p>SQL</p>
                    <p>JavaScript</p>
                    <p>Java</p>
                    <p>Python</p>
                </div>
                <div id="someE">
                    <p><b>Some Experience</b></p>
                    <p>C</p>
                    <p>R</p>
                    <p>MatLab</p>
                    <p>PHP</p>
                    <p>Git</p>
                    <p>HTML</p>
                    <p>CSS</p>
                </div>
            </div>
        )}
}