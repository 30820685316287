import React from 'react';


export default class Banner extends React.Component {
    render() {
        return (
            <div id="banner">
                <h1>Joel <b>Semeniuk</b></h1>
            </div>
        )}
}