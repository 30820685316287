import React from 'react';


export default class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <p>&#169; 2024 Joel Semeniuk </p>
            </div>
        )}
}